import React, { useEffect, useState } from "react";
import { RouterPaths } from "../Static/RouterPaths";
import { Logger } from "../Common/Shared/Logger";
import { IconX } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";


export const MeshVersionOverviewDetailsModal = React.memo(function({context, id, innerProps}){

    const API = RouterPaths.API.EXPLORE;
    const _api = (path) => {
        return `${API}${path}`;
    }

    const [details, setDetails] = useState({
        notice: "",
        crossInvalidation: false,
        inCycleOnly: true,
        descendingByVersion: false,
        descendingByPercentage: true,
        unknownAboveMajorVersion : 0,
        invalidAboveMajorVersion: 0,
        invalidBelowMajorVersion: 0,
        invalidAboveMinorVersion: 0,
        invalidBelowMinorVersion: 0,
        totalVerifiers: 0,
        totalNodes: 0,

        unknown: {
            amountOfVerifiers: 0,
            percentageOfVerifiers: 0,
            amountOfNodes: 0,
            percentageOfNodes: 0,
            verifiers: []
        },
        invalid: {
            amountOfVerifiers: 0,
            percentageOfVerifiers: 0,
            amountOfNodes: 0,
            percentageOfNodes: 0,
            verifiers: []
        },
        valid: [], // majorVersion, minorVersion, amountOf[Verifiers/Nodes] percentageOf[Verifiers/Nodes], verifiers: []
        unassigned: [] // verifier= publicIdentifierHexString, nickname, nodes

        // node = ipAddress
    });
    const [lastFetchDateTime, setLastFetchDateTime] = useState(new Date(1));
    const showDetails = lastFetchDateTime && (lastFetchDateTime.toISOString() != new Date(1).toISOString());

    const fetchDetails = async () => {
        await fetch(new Request(_api("/mesh/version-overview/details"), {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        }))
        .then(response => {
            if(!response.ok){
                Logger.LogError(1009, response);
                throw new Error("Failed to connect to endpoint", response);
            }

            return response.json();
        })
        .then(data => {
            if(data.success){
                if(data.content && Array.isArray(data.content) && data.content.length > 0){
                    let responseItem = data.content[0];

                    if(Object.keys(responseItem).includes("totalVerifiers")){
                        setDetails(responseItem);
                    } else {
                        Logger.LogError(1008, data);
                        throw new Error();
                    }

                    setLastFetchDateTime(new Date(data.createdOn));
                } else {
                    Logger.LogError(1007, data);
                    throw new Error();
                }
            } else {
                Logger.LogError(1006, data);
                throw new Error();
            }
        })
        .catch(e => {
            notifications.show({
                id: "version-overview-details-fetch-fail",
                color: "red",
                title: "Fetching version overview details failed",
                message: "Close this notification to retry",
                withCloseButton: true,
                autoClose: false,
                icon: <IconX/>,
                onClose: async () => {
                    await fetchDetails();
                }
            });
        })
    }

    useEffect(async () => {
        await fetchDetails();
    }, []);

    return (
        !showDetails ? 
        <>
        <span><i>Loading..</i></span>
        </>
        :
        <>
        <div>
            {/* title is set by modal open caller */}
        {/* <span><b>Version overview</b></span><br/> */}
        <span><i>{details.totalVerifiers} {details.inCycleOnly ? "in-cycle " : " "}verifiers</i> with </span>
        <span><i>{details.totalNodes} nodes</i></span><br/>
        <span><i>{details.unknown.amountOfNodes ?? "(?)"} unknown and {details.invalid.amountOfNodes ?? "(?)"} invalid node versions</i></span><br/>
        <span style={{fontSize: "0.85em"}}><i>Fetched on: {lastFetchDateTime.toISOString()}</i></span><br/><br/>
        <span>Versions are invalid when unparseable or below the first documented major version release ({details.invalidBelowMajorVersion}), unknown above the last documented major version release by <a href="https://construct0.com" target="_blank" style={{color:"white"}}>construct0</a> ({details.unknownAboveMajorVersion}). Entries in the valid category are ordered descending by version whereby the order is determined by the amount of verifiers which run a version.</span>
        <br/><br/>
        <span id="version-overview-details-notice">A verifier with 3 nodes can be present in at most 3 categories (unknown, invalid, valid) at the same time.<br/>The 'percentage of total amount of verifiers' per category only pertains to that category, not across categories - which may result in a small deviation from the expected 100% when creating the cross-categorical sum.<br/>A node can be present in at most 1 category and as such the 'percentage of total amount of nodes' can be summed up across categories without deviations other than those due to rounding off to two decimal points.</span><br/>
        </div>

        <div><hr/></div>

        {/* unknown */}
        <div>
            <span><b>Unknown</b></span><br/>
            <span><i>{details.unknown.amountOfNodes ?? "(?)"} unknown node versions of {details.unknown.amountOfVerifiers ?? "(?)"} verifiers</i></span><br/>
            <span><i>{details.unknown.percentageOfVerifiers?.toFixed(2) ?? "(?)"}% of all verifiers, {details.unknown.percentageOfNodes?.toFixed(2) ?? "(?)"}% of all nodes</i></span><br/>
            {
                details.unknown.verifiers?.length > 0 &&
                <table className="w-100">
                    <thead>
                        <tr>
                            <td><b>Primary nickname</b></td>
                            <td><b>Public identifier</b></td>
                            <td><b>Node(s)</b></td>
                        </tr>
                    </thead>
                    <tbody>
                {
                    details.unknown.verifiers.map((entry, idx) => {
                        let entryNodes = entry.nodes.map((nodeEntry, nodeIdx) => {
                            return nodeEntry.ipAddress;
                        });

                        return (
                            <tr>
                                <td style={{verticalAlign: "baseline", wordBreak: "break-all"}}><span>{entry.nickname}</span></td>
                                <td style={{verticalAlign: "baseline", wordBreak: "break-all"}}><span>{entry.publicIdentifierHexString}</span></td>
                                <td style={{verticalAlign: "baseline"}}><span>{entryNodes.map((en, enIdx) => {
                                    return (
                                        <span>{en}{entryNodes.length != enIdx + 1 ? <><span>,</span><br/></> : <></>}</span>
                                    )
                                })}</span></td>
                            </tr>
                        )
                    })
                }
                {/* id:nickname - ips ,\n   table */}
                    </tbody>
                </table>
            }
        </div>

        <div><hr/></div>

        {/* invalid */}
        <div>
            <span><b>Invalid</b></span><br/>
            <span><i>{details.invalid.amountOfNodes ?? "(?)"} invalid node versions of {details.invalid.amountOfVerifiers ?? "(?)"} verifiers</i></span><br/>
            <span><i>{details.invalid.percentageOfVerifiers?.toFixed(2) ?? "(?)"}% of all verifiers, {details.invalid.percentageOfNodes?.toFixed(2) ?? "(?)"}% of all nodes</i></span><br/>
            {
                details.invalid.verifiers?.length > 0 &&
                <table className="w-100">
                    <thead>
                        <tr>
                            <td><b>Primary nickname</b></td>
                            <td><b>Public identifier</b></td>
                            <td><b>Node(s)</b></td>
                        </tr>
                    </thead>
                    <tbody>
                {
                    details.invalid.verifiers.map((entry, idx) => {
                        let entryNodes = entry.nodes.map((nodeEntry, nodeIdx) => {
                            return nodeEntry.ipAddress;
                        });

                        return (
                            <tr>
                                <td style={{verticalAlign: "baseline", wordBreak: "break-all"}}><span>{entry.nickname}</span></td>
                                <td style={{verticalAlign: "baseline", wordBreak: "break-all"}}><span>{entry.publicIdentifierHexString}</span></td>
                                <td style={{verticalAlign: "baseline"}}><span>{entryNodes.map((en, enIdx) => {
                                    return (
                                        <span>{en}{entryNodes.length != enIdx + 1 ? <><span>,</span><br/></> : <></>}</span>
                                    )
                                })}</span></td>
                            </tr>
                        )
                    })
                }
                {/* id:nickname - ips ,\n   table */}
                    </tbody>
                </table>
            }
        </div>

        <div><hr/></div>

        {/* valid */}
        <div>
            <span><b>Valid</b></span><br/>
            <span><i>{details.valid?.length ?? "(?)"} distinct node versions detected</i></span><br/>
            {
                details.valid?.length > 0 &&
                <>
                    {
                        details.valid.map((entry, idx) => {
                            return (
                                <>
                                <br/>
                                <span><b>Version {entry.majorVersion}.{entry.minorVersion}</b></span><br/>
                                <span><i>{entry.amountOfNodes ?? "(?)"} nodes of {entry.amountOfVerifiers ?? "(?)"} verifiers</i></span><br/>
                                <span><i>{entry.percentageOfVerifiers?.toFixed(2) ?? "(?)"}% of all verifiers, {entry.percentageOfNodes?.toFixed(2) ?? "(?)"}% of all nodes</i></span><br/>
                                {
                                    entry.verifiers?.length > 0 &&
                                    <table className="w-100">
                                        <thead>
                                            <tr>
                                                <td><b>Primary nickname</b></td>
                                                <td><b>Public identifier</b></td>
                                                <td><b>Node(s)</b></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                entry.verifiers.map((verifier, verifierIdx) => {
                                                    let verifierNodes = verifier.nodes.map((verifierNode, verifierNodeIdx) => {
                                                        return verifierNode.ipAddress;
                                                    });

                                                    return (
                                                        <tr>
                                                            <td style={{verticalAlign: "baseline", wordBreak: "break-all"}}><span>{verifier.nickname}</span></td>
                                                            <td style={{verticalAlign: "baseline", wordBreak: "break-all"}}><span>{verifier.publicIdentifierHexString}</span></td>
                                                            <td style={{verticalAlign: "baseline"}}><span>{verifierNodes.map((en, enIdx) => {
                                                                return (
                                                                    <span>{en}{verifierNodes.length != enIdx + 1 ? <><span>,</span><br/></> : <></>}</span>
                                                                )
                                                            })}</span></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                }
                                </>
                            )
                        })
                    }
                </>
            }
        </div>

        <div><hr/></div>


        {/* unassigned */}
        <div>
            <span><b>Unassigned</b></span><br/>
            <span><i>{details.unassigned.amountOfVerifiers ?? "(?)"} verifiers without any node</i></span><br/>
            <span><i>{details.unassigned.percentageOfVerifiers?.toFixed(2) ?? "(?)"}% of all verifiers</i></span><br/>
            {
                details.unassigned.verifiers?.length > 0 &&
                <table className="w-100">
                    <thead>
                        <tr>
                            <td><b>Primary nickname</b></td>
                            <td><b>Public identifier</b></td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            details.unassigned.verifiers.map((entry, idx) => {
                                return (
                                    <tr>
                                        <td style={{verticalAlign: "baseline", wordBreak: "break-all"}}><span>{entry.nickname}</span></td>
                                        <td style={{verticalAlign: "baseline", wordBreak: "break-all"}}><span>{entry.publicIdentifierHexString}</span></td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            }
        </div>
        </>
    )

})




