import { ActionIcon, Box, Card, Center, Grid, Group, Progress, ScrollArea, SegmentedControl, Space, Stack, Tabs, Text, createStyles } from "@mantine/core";
import { Content, DynamicHeightSegment, Segment } from "../Common/Content";
import { IconAffiliateFilled, IconCircleChevronDown, IconMessageCircle, IconPhoto, IconSettings, IconX } from "@tabler/icons-react";
import { ArrowBearLeft2, ArrowBounce, Percentage, SortDescending, StatusChange } from "tabler-icons-react";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../Common/Wrappers/AppWrapper";
import { useLocalStorage } from "@mantine/hooks";
import { RouterPaths } from "../Static/RouterPaths";
import { Logger } from "../Common/Shared/Logger";
import { notifications } from "@mantine/notifications";
import { modals } from "@mantine/modals";

const cloneDeep = require("lodash.clonedeep");


const useMeshSegmentStyles = createStyles((theme) => ({
    cardTopLevel: {
        height: "100%",
        maxHeight: "100%",
        paddingRight: "0px!important",
        paddingLeft: "0px!important",
        paddingTop: "0rem!important", // > 1rem default
        paddingBottom: "0rem!important", // > "
        // borderTop: "0.1px solid white",
        // borderLeft: "0.1px"
        // border: "0.1px solid white",
        WebkitBoxShadow: "0px 0px 1px 1px rgba(255,255,255,0.49);",
        MozBoxShadow: "0px 0px 1px 1px rgba(255,255,255,0.49);",
        boxShadow: "0px 0px 1px 1px rgba(255,255,255,0.49);"
    },

    affixTopLevel: {
        height: "100%",
        maxHeight: "100%",
        width: "100%",
        maxWidth: "100%",
        paddingTop: "0px!important",
        paddingBottom: "0px!important",
        paddingLeft: "calc(1rem/4)",
        paddingRight: "calc(1rem/4)",
    },

    gridTopLevel: {
        margin: 0,
        paddingRight: 0,
        paddingLeft: 0
    },

    column: {
        paddingLeft: 0,
        paddingRight: 0,
    },

    iframe: {
        width: "100%",
        height: "100%",
    },

    tabsroot: {
        width: "100%",
        height: "100%",
    },
        tabslist: {
            width: "100%",
            // height: "100%",
        },
        tabspanel: {
            width: "100%",
            height: "100%",
            paddingTop: "0px!important",
        },

}));


function TabContent({subdomain, index, versionOverviewVisible, setVersionOverviewVisible, versionTopBy, setVersionTopBy, versionOverviewResponse, lastVersionOverviewFetchDateTime}){
    const { isMobile, isTablet } = useContext(AppContext);
    const [ initialized, setInitialized ] = useState(false);

    useEffect(() => {
        const intervalId = setInterval(() => {
            fetch(`https://proxy.nyzo.org/${subdomain}.html?ts=`+Date.now()).then(response => {return response.text()}).then((response)=>{
                if(response.includes("Frozen edge")){
                    response = response.replace("<script>", `<script id="iframeContentDivScript${index}">`);

                    let cycleLength = response.split("&rarr;").length;

                    response = response.replace("<h3>(", `<h5>Cycle length: ${cycleLength}</h5><i>A: upgraded before soft-fork<br/>B: upgraded after soft-fork</i><br/><br/><h3>(`)

                    let target = document.getElementById("iframeContentDiv"+index);
                    target.innerHTML = response;

                    // this should happen before the .innerHTML, yet the possible delay is no problem in this case
                    document.getElementById("iframeContentDivScript"+index)?.remove();

                    if(!initialized){
                        setInitialized(true);
                    }
                }    
            }).catch((e)=>{console.log(e)})
        }, 1000);

        /*~*/ return () => clearInterval(intervalId);
    }, []);

    const {
        inCycleOnly,
        totalVerifiers,
        totalNodes,

        unknown,
        invalid,

        entries : versionEntries
    } = versionOverviewResponse;

    const openVersionDetailsModal = () => {
        modals.openContextModal({
            modal: "MeshVersionOverviewDetailsModal",
            centered: true,
            size: "xl",
            scrollAreaComponent: ScrollArea.Autosize,
            closeOnClickOutside: true,
            closeOnEscape: true,
            trapFocus: false,

            title: "Version overview details",
            innerProps: {}
        });
    }

    return (
        <ScrollArea.Autosize
            type="auto"
            scrollbarSize={4}
            style={{
                maxHeight: "100%"
            }}
        >
            <div>
                {
                    (!versionOverviewVisible && initialized) &&
                    <div
                        title="Show version overview"
                        style={{
                            position: "absolute",
                            bottom: "3em",
                            right: "0.75em",
                            // width: "5em",
                            // height: "5em",
                            // borderRadius: "10em",
                            // backgroundColor: "red",
                            // textAlign: "center",
                        }}

                        onClick={() => setVersionOverviewVisible(!versionOverviewVisible)}
                    >
                        <ActionIcon
                            title="Show version overview"
                            variant="filled"
                            size="xl"
                            radius="xl"
                            color="teal"
                        >
                            <IconAffiliateFilled
                                className="mx-2"
                            />
                        </ActionIcon>
                        {/* <span style={{ fontSize: "0.8em" }}>Versions</span> */}
                    </div>
                }

                {
                    (versionOverviewVisible && initialized) &&
                    <div
                        style={{
                            display: versionOverviewVisible ? "" : "none",

                            position: "absolute",
                            bottom: "2.1em", // < ?
                            width: "100%",
                            height: isMobile ? "92%" : "auto",
                            borderRadius: 0,
                            // backgroundColor: "#383940",
                            backgroundColor: "rgba(56, 57, 64, 0.97)",
                            // borderTop: "1px solid #ffffffa3",
                            borderTop: "1px solid rgba(255, 255, 255, 0.18)",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: isMobile ? "wrap" : ""

                            // flexWrap: "wrap"
                        }}
                    >
                        {/* 
                        
                        https://api.construct0.com/api/explore/mini/v1/mesh/version-overview?leadByPercentage=true
                        
                        */}

                        <div
                            title="Hide version overview"
                            style={{
                                position: "absolute",
                                right: "0.15em",
                                top: "0.3em",
                                width: "1.8em",
                                height: "1.8em",
                                zIndex: 20001,
                                // backgroundColor: "cyan"
                            }}
                        >
                            <ActionIcon
                                onClick={() => setVersionOverviewVisible(!versionOverviewVisible)}
                            >
                                <IconCircleChevronDown/>
                            </ActionIcon>
                        </div>

                        <div
                            style={{
                                width: (isTablet || isMobile ? "100%" : "30%"),
                                margin: "0.4em",
                            }}
                        >
                            <Stack
                                justify="space-between"
                                h="100%"
                                style={{
                                    margin: "0.4em"
                                }}
                            >
                                <div>
                                <span><b>Version overview</b></span><br/>
                                <span><i>{totalVerifiers} {inCycleOnly ? "in-cycle " : " "}verifiers</i> with </span>
                                <span><i>{totalNodes} nodes</i></span><br/>
                                <span style={{fontSize: "0.85em"}}><i>Last updated: {lastVersionOverviewFetchDateTime.toISOString()}</i></span><br/>
                                <span onClick={() => openVersionDetailsModal()}><i><u>Click here to view more details</u></i></span>
                                </div>

                                <div
                                    style={{
                                        marginBottom: "0.8em"
                                    }}
                                >
                                <hr style={{
                                    margin: 0,
                                    marginTop: "-1.4em",
                                    marginBottom: "0.4em"
                                }}/>

                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    <SegmentedControl
                                        style={{
                                            width: "100%"
                                        }}
                                        value={versionTopBy}
                                        onChange={setVersionTopBy}
                                        data={[
                                            {
                                                value: "pct-desc",
                                                label: (
                                                    <Center>
                                                        {
                                                            !(isMobile || isTablet) &&
                                                            <Percentage style={{marginRight: 5}} color="teal" size="1em"/>
                                                        }
                                                        <Box>Top by pct</Box>
                                                    </Center>
                                                )
                                            },
                                            {
                                                value: "version-desc",
                                                label: (
                                                    <Center>
                                                        <Box>Top by version</Box>
                                                        {
                                                            !(isMobile || isTablet) &&
                                                            <SortDescending style={{marginLeft: 5}} color="teal" size="1em"/>
                                                        }
                                                        
                                                    </Center>
                                                )
                                            }
                                        ]}
                                    />
                                </div>
                                </div>
                            </Stack>
                            
                        </div>
                        <div
                            style={{
                                // backgroundColor: "red",
                                height: isMobile ? "60%" : "auto",
                                width: isMobile ? "100%" : "75%",
                                overflowX: isMobile ? "hidden" : "scroll",
                                overflowY: isMobile ? "scroll" : "hidden",
                                scrollbarWidth: "thin",
                                scrollbarGutter: "stable",
                                scrollbarColor: "darkgrey",
                                display: "flex",
                                flexDirection: isMobile ? "column" : "row",
                                // borderLeft: "1px solid #ffffffa3",
                                margin: "0.4em"
                            }}
                            title="Click anywhere to open version details"
                            onClick={() => openVersionDetailsModal()}
                        >
                            {
                                versionEntries.map((entry, idx) => {

                                    return (
                                        <div
                                            key={"version-overview-entry-" + idx}
                                            style={{
                                                marginTop: 0, marginBottom: 0,
                                                margin: "0.2em"
                                            }}
                                        >
                                            <Card
                                                withBorder={false}
                                                shadow="xs"
                                                radius="sm"
                                                style={{
                                                    height: "auto",
                                                    minWidth: "14em"
                                                }}
                                            >
                                                <Card.Section
                                                    withBorder={true}
                                                    inheritPadding={true}
                                                    py="xs"
                                                    className="mb-2"
                                                >
                                                    <span>v{entry.majorVersion}.{entry.minorVersion}</span>
                                                </Card.Section>
                                            
                                                <span>{entry.amountOfVerifiers} {entry.amountOfVerifiers != 1 ? "verifiers" : "verifier"} with </span>
                                                <span>{entry.amountOfNodes} {entry.amountOfNodes != 1 ? "nodes" : "node"}</span><br/>
                                                <hr style={{margin: 0, marginTop: "0.3em", marginBottom: "0.3em"}}/>
                                                {/* <span>Share</span><br/> */}
                                                <span>{entry.percentageOfVerifiers.toFixed(2)}% of all in-cycle verifiers</span><br/>
                                                <span>{entry.percentageOfNodes.toFixed(2)}% of all active nodes</span>
                                            </Card>
                                        </div>
                                    );
                                })
                            }


                            


                        {
                            isMobile &&
                            <div
                                style={{marginTop: "10em"}}
                            >
                            </div>
                        }


                        </div>
                    </div>
                }

                {/* mesh proxy */}
                <div id={"iframeContentDiv"+index} style={{
                    textAlign: "center",
                    padding: "1em",
                    paddingBottom: (versionOverviewVisible && !isMobile) ? "15em" : "4em"
                }}>
                    Loading..
                </div>
            </div>
        </ScrollArea.Autosize>
    );
}

function MeshSegment(){
    const { classes } = useMeshSegmentStyles();
    
    const { isMobile, isTablet } = useContext(AppContext);
    const API = RouterPaths.API.EXPLORE;
    const _api = (path) => {
        return `${API}${path}`;
    }

    // Version overview
    // pct-desc, version-desc
    const [versionTopBy, setVersionTopBy] = useState("pct-desc");

    const [versionOverviewVisible, setVersionOverviewVisible] = useLocalStorage({
        key: "mesh-version-overview-visible",
        defaultValue: isMobile ? false : true, // cast
        serialize: (value) => {
            return value ? 1 : 0;
        },
        deserialize: (valueInt) => {
            return valueInt == 1 ? true : false;
        }
    });

    // Inner response w/o createdOn 
    const [versionOverviewResponse, setVersionOverviewResponse] = useState({
        inCycleOnly: false,
        totalVerifiers: 0,
        totalVerifiers: 0,
        unknown: {
            amountOfVerifiers: 0,
            amountOfNodes: 0,
        },
        invalid: {
            amountOfVerifiers: 0,
            amountOfNodes: 0
        },
        entries: []
    });
    const [lastVersionOverviewFetchDateTime, setLastVersionOverviewFetchDateTime] = useState(new Date(0));

    const fetchVersions = async (versionTopByOverride) => {
        let versionTopByAlt = versionTopByOverride ?? versionTopBy;
        let leadByPercentage = versionTopByAlt == "pct-desc" ? "true" : "false";

        await fetch(new Request(_api("/mesh/version-overview?leadByPercentage=" + leadByPercentage), {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }                      
        }))
        .then(response => {
            if(!response.ok){
                Logger.LogError(1001, response);
                throw new Error("Failed to connect to endpoint", response);
            }

            return response.json();
        })
        .then(data => {
            if(data.success){
                if(data.content && Array.isArray(data.content) && data.content.length > 0){
                    let responseItem = data.content[0];

                    if(Object.keys(responseItem).includes("totalVerifiers")){
                        setVersionOverviewResponse(responseItem);
                    } else {
                        Logger.LogError(1000, data);
                        throw new Error();
                    }

                    setLastVersionOverviewFetchDateTime(new Date(data.createdOn));
                } else {
                    Logger.LogError(999, data);
                    throw new Error();
                }
            } else {
                Logger.LogError(998, data);
                throw new Error();
            }
        }).catch(e => {
            notifications.show({
                id: "version-overview-fetch-fail",
                color: "red",
                title: "Fetching version overview failed",
                message: "Close this notification to retry",
                withCloseButton: true,
                autoClose: false,
                icon: <IconX/>,
                onClose: async () => {
                    await fetchVersions();
                }
            });
        })
    }

    useEffect(async () => {
        await fetchVersions();
    }, [versionTopBy]);

    useEffect(() => {
        if(isMobile){
            setVersionOverviewVisible(false);
        }
    }, []);

    useEffect(() => {
        const intervalId = setInterval(async () => {
            setVersionTopBy((curr) => {
                fetchVersions(cloneDeep(curr));

                return curr;
            })
        }, 30_000);

        /*~*/ return () => clearInterval(intervalId);
    }, []);

    return (
        <Segment
            id="mesh-segment-wrapper"
            classes={isMobile ? " " : null}
        >
            <DynamicHeightSegment
                preludeElements={["navbar"]}
                id="mesh-segment"
                classes={isMobile ? " " : "mt-3 mb-3"}
                style={{
                    maxWidth: "100%",
                    width: "100%"
                }}
            >
                <Card
                    id="mesh-segment-main-wrapper"
                    className={classes.cardTopLevel}
                >
                    <Grid
                        columns={12}
                        id="mesh-segment-main"
                        grow={true} // < width maximizer
                        className={`${classes.affixTopLevel} ${classes.gridTopLevel}`} // < height maximizer
                    >
                        <Grid.Col
                            id="mesh-segment-main-0"
                            className={`${classes.affixTopLevel} ${classes.column}`}
                            span={12}
                        >
                            <Tabs 
                                defaultValue="v643_2_0"
                                className={classes.tabsroot}
                            >
                                <Tabs.List
                                    className={classes.tabslist}
                                >
                                    <Tabs.Tab 
                                        value="v643_2_0" 
                                        icon={<ArrowBounce size="0.8rem" />}
                                        style={{
                                            width:"33.33%"
                                        }}
                                    >
                                        v643.2
                                    </Tabs.Tab>
                                    <Tabs.Tab 
                                        value="v644_x_0" 
                                        icon={<StatusChange size="0.8rem" />}
                                        style={{
                                            width:"33.33%"
                                        }}
                                    >
                                        v644 (A)
                                    </Tabs.Tab>
                                    <Tabs.Tab 
                                        value="v644_x_1" 
                                        icon={<StatusChange size="0.8rem" />}
                                        style={{
                                            width:"33.33%"
                                        }}
                                    >
                                        v644 (B)
                                    </Tabs.Tab>
                                </Tabs.List>

                                <Tabs.Panel 
                                    id="v643_2_0-iframe-wrapper"
                                    value="v643_2_0" 
                                    pt="xs"
                                    className={classes.tabspanel}
                                >
                                    <TabContent
                                        subdomain={"verifier2"}
                                        index={0}

                                        versionOverviewVisible={versionOverviewVisible}
                                        setVersionOverviewVisible={setVersionOverviewVisible}
                                        versionTopBy={versionTopBy}
                                        setVersionTopBy={setVersionTopBy}
                                        versionOverviewResponse={versionOverviewResponse}
                                        lastVersionOverviewFetchDateTime={lastVersionOverviewFetchDateTime}
                                    />
                                </Tabs.Panel>

                                <Tabs.Panel 
                                    id="v644_x_0-iframe-wrapper"
                                    value="v644_x_0" 
                                    pt="xs"
                                    className={classes.tabspanel}
                                >
                                    <TabContent
                                        subdomain={"verifier0"}
                                        index={1}

                                        versionOverviewVisible={versionOverviewVisible}
                                        setVersionOverviewVisible={setVersionOverviewVisible}
                                        versionTopBy={versionTopBy}
                                        setVersionTopBy={setVersionTopBy}
                                        versionOverviewResponse={versionOverviewResponse}
                                        lastVersionOverviewFetchDateTime={lastVersionOverviewFetchDateTime}
                                    />
                                    
                                </Tabs.Panel>

                                <Tabs.Panel 
                                    id="v644_x_1-iframe-wrapper"
                                    value="v644_x_1" 
                                    pt="xs"
                                    className={classes.tabspanel}
                                >
                                    <TabContent
                                        subdomain={"verifier1"}
                                        index={2}

                                        versionOverviewVisible={versionOverviewVisible}
                                        setVersionOverviewVisible={setVersionOverviewVisible}
                                        versionTopBy={versionTopBy}
                                        setVersionTopBy={setVersionTopBy}
                                        versionOverviewResponse={versionOverviewResponse}
                                        lastVersionOverviewFetchDateTime={lastVersionOverviewFetchDateTime}
                                    />
                                </Tabs.Panel>
                            </Tabs>
                        </Grid.Col>
                    </Grid>
                </Card>
            </DynamicHeightSegment>
        </Segment>
    )
}

function MeshBeta(){
    const { isMobile } = useContext(AppContext);

    return (
        <Content
            id="mesh-page" 
            style={{
                maxWidth: "96.5rem", 
                width: "100%",
                paddingLeft: isMobile ? 0 : undefined,
                paddingRight: isMobile ? 0 : undefined
            }}
        >
            <MeshSegment/>
        </Content>
    )
}

export default MeshBeta;



